<template>
  <div>
    <v-btn @click="dialog = true" text small
      ><v-icon>mdi-text-box-search-outline</v-icon>Price Check</v-btn
    >
    <v-dialog v-model="dialog" persistent width="800">
      <v-card>
        <v-card-title
          >Price Check Modal <v-spacer></v-spacer>

          <v-btn @click="dialog = false"> Close</v-btn></v-card-title
        >
        <v-card-text>
          <div>
            <v-text-field
              v-model="search_item"
              label="Search Item"
              prepend-icon="mdi-magnify"
            ></v-text-field>
            <v-data-table
              :items="items"
              :headers="items_headers"
              :search="search_item"
            ></v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search_item: "",
      search_service: "",
      button_text: "Items",
      dialog: false,
      loading: false,
      items_headers: [
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Selling Price", value: "selling_price" },
        { text: "Batch ID", value: "import_batch.batch_description" },
        { text: "Quantity", value: "quantity" },
      ],
      services_headers: [
        { text: "ID", value: "id" },
        { text: "Service Name", value: "service_name" },
        { text: "Service Amount", value: "service_amount" },
      ],
    };
  },
  methods: {
    change_button_text() {
      this.button_text == "Items"
        ? (this.button_text = "Services")
        : (this.button_text = "Items");
    },
    submit() {
      return;
    },
    ...mapActions({
      get_items: "item/get_items",
      get_services: "service/get_services",
    }),
  },
  computed: {
    ...mapGetters({
      items: "item/items",
    }),
  },
  created() {
    if (this.items.length <= 0) {
      this.get_items();
    }
  },
};
</script>

<style lang="scss" scoped></style>
