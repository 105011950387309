import axios from "axios";
export default {
  namespaced: true,
  state: {
    problem: null,
    problems: [],
    all_problems: [],
    problem_summary: [],
    project_photos: [],
  },
  getters: {
    problem(state) {
      return state.problem;
    },
    problems(state) {
      return state.problems;
    },
    all_problems(state) {
      return state.all_problems;
    },
    problem_summary(state) {
      return state.problem_summary;
    },
    project_photos(state) {
      return state.project_photos;
    },
  },
  mutations: {
    SET_PROJECT_PHOTOS(state, project_photos) {
      state.project_photos = project_photos;
    },
    ADD_PROJECT_PHOTO(state, project_photo) {
      state.project_photos.unshift(project_photo);
    },
    SET_PROBLEM_SUMMARY(state, problem_summary) {
      state.problem_summary = problem_summary;
    },
    SET_PROBLEM(state, problem) {
      state.problem = problem;
    },
    SET_ALL_PROBLEMS(state, all_problems) {
      state.all_problems = all_problems;
    },
    ADD_PROBLEM(state, problem) {
      state.problems.unshift(problem);
    },
    SET_PROBLEMS(state, problems) {
      state.problems = problems;
    },
    DELETE_PROBLEM(state, problem_id) {
      state.problems.filter((u) => {
        return u.id == problem_id;
      });
    },
    UPDATE_PROBLEM(state, problem) {
      var index = state.problems.findIndex((a) => a.id == problem.id);
      state.problems.splice(index, 1, problem);

      // state.problems.forEach(p=>{
      //     if(p.id == problem.id){
      //         return p = problem;
      //     }
      // })
    },
  },
  actions: {
    async get_project_photos({ commit }, problem_id) {
      let response = await axios.get("/project_photos/" + problem_id);
      commit("SET_PROJECT_PHOTOS", response.data);
    },

    async add_project_photo({ commit }, request) {
      let response = await axios.post("/project_photo", request);
      commit("ADD_PROJECT_PHOTO", response.data);
    },
    async get_problem_summary({ commit }, user_id) {
      let response = await axios.get(
        "/get_problems_summary_per_user/" + user_id
      );
      commit("SET_PROBLEM_SUMMARY", response.data);
    },
    set_problem({ commit }, problem) {
      commit("SET_PROBLEM", problem);
    },
    async get_all_problems({ commit }) {
      let response = await axios.get("/problems");
      commit("SET_ALL_PROBLEMS", response.data);
    },
    async add_problem(_, problem) {
      let response = await axios.post("/problem", problem);
      return response;
    },
    async get_problems({ commit }, problem_id) {
      let response = await axios.get("/problems/" + problem_id);
      commit("SET_PROBLEMS", response.data);
    },
    async delete_problem(_, problem_id) {
      await axios.delete("/problem/" + problem_id);
    },
    async update_problem({ commit }, problem) {
      let response = await axios.put("/problem", problem).catch((e) => {
        alert(e);
      });
      commit("UPDATE_PROBLEM", response.data);
    },
  },
};
