import axios from "axios";
export default {
  namespaced: true,
  state: {
    items: [],
    all_items: [],
    returned_items: [],
    item_orders: [],
    item_order_for_add: null,
  },
  getters: {
    item_order_for_add(state) {
      return state.item_order_for_add;
    },
    items(state) {
      return state.items;
    },
    all_items(state) {
      return state.all_items;
    },
    returned_items(state) {
      return state.returned_items;
    },
    item_orders(state) {
      return state.item_orders;
    },
  },
  mutations: {
    DELETE_ITEM_ORDER(state, item_order_id) {
      let filtered = state.items.filter((c) => {
        return c.id != item_order_id;
      });
      state.items = filtered;
    },
    SET_ITEM_ORDER_FOR_ADD(state, item) {
      state.item_order_for_add = item;
    },
    // ITEM ORDERS
    SET_ITEM_ORDERS(state, items) {
      state.item_orders = items;
    },
    ADD_ITEM_ORDER(state, item) {
      state.item_orders.unshift(item);
    },
    EDIT_ITEM_ORDER(state, item) {
      var index = state.item_orders.findIndex((a) => a.id == item.id);
      state.item_orders.splice(index, 1, item);
    },

    // RETURNED ITEM
    EDIT_RETURNED_ITEM(state, item) {
      var index = state.returned_items.findIndex((a) => a.id == item.id);
      state.returned_items.splice(index, 1, item);
    },
    DELETE_RETURNED_ITEM(state, item_id) {
      let filtered = state.returned_items.filter((c) => {
        return c.id != item_id;
      });
      state.returned_items = filtered;
    },
    RESTORE_ITEM(state, item) {
      // remove from all items
      let filtered_all_items = state.all_items.filter((c) => {
        return c.id != item.id;
      });
      state.all_items = filtered_all_items;

      // add to items
      state.items.unshift(item);
    },
    SET_RETURNED_ITEMS(state, returned_items) {
      state.returned_items = returned_items;
    },
    ADD_RETURNED_ITEM(state, item) {
      state.returned_items.unshift(item);
    },
    SET_ITEMS(state, items) {
      // filter not deleted
      var not_deleted = items.filter((i) => {
        return i.is_deleted == 0;
      });
      state.items = not_deleted;
      // get all items except pre-order components
      // check if item has batch

      var all_but_not_pre_order = items.filter((i) => {
        return i.import_batch.batch_description !== "PRE-ORDER COMPONENTS";
      });
      state.all_items = all_but_not_pre_order;
    },
    ADD_ITEM(state, item) {
      state.items.unshift(item);
    },
    EDIT_ITEM(state, item) {
      var index = state.items.findIndex((a) => a.id == item.id);
      state.items.splice(index, 1, item);
    },
    DELETE_ITEM(state, item_id) {
      let filtered = state.items.filter((c) => {
        return c.id != item_id;
      });
      state.items = filtered;
    },
  },
  actions: {
    async delete_item_order({ commit }, item_order_id) {
      await axios.delete("/item_order/" + item_order_id);
      commit("DELETE_ITEM_ORDER", item_order_id);
    },
    set_item_order_for_add({ commit }, request) {
      commit("SET_ITEM_ORDER_FOR_ADD", request);
    },
    // ITEM ORDERS
    async add_item_order({ commit }, request) {
      let response = await axios.post("/item_order", request);
      commit("ADD_ITEM_ORDER", response.data);
    },
    async get_item_orders({ commit }, request) {
      let response = await axios.get("/item_orders", request);
      commit("SET_ITEM_ORDERS", response.data);
    },
    async edit_item_order({ commit }, item) {
      await axios.put("/item_order", item);
      commit("EDIT_ITEM_ORDER", item);
    },
    // RETURNED ITEMS
    async edit_returned_item({ commit }, item) {
      let response = await axios.put("/returned_item", item);
      console.log(response.data);
      commit("EDIT_RETURNED_ITEM", response.data);
    },
    async delete_returned_item({ commit }, item_id) {
      await axios.delete("/returned_item/" + item_id);
      commit("DELETE_RETURNED_ITEM", item_id);
    },

    async restore_item({ commit }, request) {
      let response = await axios.post("/restore_item", request);
      commit("RESTORE_ITEM", response.data);
    },
    async get_returned_items({ commit }) {
      let response = await axios.get("/returned_items");
      // console.log(response.data);
      commit("SET_RETURNED_ITEMS", response.data);
    },
    async add_returned_item({ commit }, request) {
      let response = await axios.post("/returned_item", request);
      commit("ADD_RETURNED_ITEM", response.data);
    },
    async get_items({ commit }) {
      let response = await axios.get("/items");
      commit("SET_ITEMS", response.data);
    },
    async add_item({ commit }, request) {
      let response = await axios.post("/item", request);
      commit("ADD_ITEM", response.data);
    },
    async edit_item({ commit }, request) {
      let response = await axios.put("/item", request);
      commit("EDIT_ITEM", response.data);
    },
    async add_stock({ commit }, request) {
      let response = await axios.post("/add_stock", request);
      commit("EDIT_ITEM", response.data);
    },
    async delete_item({ commit }, item_id) {
      await axios.delete("/item/" + item_id);
      commit("DELETE_ITEM", item_id);
    },
  },
};
