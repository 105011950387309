<template>
  <div v-if="user">
    <v-app-bar flat absolute>
      <v-spacer></v-spacer>

      <!-- visible only to level 4 and up -->
      <!-- <item-orders-modal v-if="this.user.access_level >= 4" /> -->
      <!-- visible only to access level 2 and up -->
      <!-- <pending-units-modal v-if="this.user.access_level >= 2" /> -->
      <!-- <problems-modal v-if="this.user.access_level >= 2" /> -->

      <!-- visible only to access level 3 and up -->

      <!-- <returned-items-modal v-if="this.user.access_level >= 3" /> -->
      <reminders-modal :user="user" v-if="this.user.access_level >= 3" />
      <!-- <add-expense-modal v-if="this.user.access_level >= 3" /> -->
      <price-check-modal v-if="this.user.access_level >= 3" />
      <h3 class="blue--text">
        {{ user.username }}
      </h3>
      <h3 class="ml-2">{{ user.branch.branch_name }}</h3>
      <v-btn icon class="mr-2" @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ReturnedItemsModal from "../Item/returnedItemsModal.vue";
// import ItemOrdersModal from "../ItemOrders/itemOrdersModal.vue";
// import AddExpenseModal from "../Payment/addExpenseModal.vue";
// import ProblemsModal from "../problemsModal.vue";
import RemindersModal from "../remindersModal.vue";
import PriceCheckModal from "../priceCheckModal.vue";
// import PendingUnitsModal from "../pendingUnitsModal.vue";

export default {
  data() {
    return {
      restricted_account: "technician",
    };
  },
  components: {
    RemindersModal,
    // ReturnedItemsModal,
    // ProblemsModal,

    // AddExpenseModal,
    // ItemOrdersModal,
    PriceCheckModal,
    // PendingUnitsModal,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      daily_reminders: "reminder/daily_reminders",
      reminders: "reminder/reminders",
    }),
  },
  created() {
    this.check_if_empty();
  },
  methods: {
    ...mapActions({
      signout: "auth/signout",

      get_reminders: "reminder/get_reminders",
    }),
    changeRoute(route) {
      this.$router.push(route);
    },
    check_if_empty() {
      if (this.reminders) {
        if (this.reminders.length == 0) {
          this.get_reminders(this.user.role == "admin" ? 1 : 0).then(() => {
            this.ready = true;
          });
        }
      }
    },
    logout() {
      this.signout();
      setTimeout(() => {
        this.$router.push("/signin");
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
