import axios from "axios";
export default {
  namespaced: true,
  state: {
    import_batches: [],
  },
  getters: {
    import_batches(state) {
      return state.import_batches;
    },
  },
  mutations: {
    SET_IMPORT_BATCHES(state, import_batches) {
      state.import_batches = import_batches;
    },
    ADD_IMPORT_BATCH(state, import_batch) {
      state.import_batches.unshift(import_batch);
    },
    EDIT_IMPORT_BATCH(state, import_batch) {
      var index = state.import_batches.findIndex(
        (a) => a.id == import_batch.id
      );
      state.import_batches.splice(index, 1, import_batch);
    },
  },
  actions: {
    async get_import_batches({ commit }) {
      let response = await axios.get("/import_batches");
      commit("SET_IMPORT_BATCHES", response.data);
    },
    async add_import_batch({ commit }, request) {
      let response = await axios.post("/import_batch", request);
      commit("ADD_IMPORT_BATCH", response.data);
    },
    async edit_import_batch({ commit }, request) {
      let response = await axios.put("/import_batch", request);
      commit("EDIT_IMPORT_BATCH", response.data);
    },
    async add_stock({ commit }, request) {
      let response = await axios.post("/add_stock", request);
      commit("EDIT_IMPORT_BATCH", response.data);
    },
  },
};
