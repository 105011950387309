import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueHtmlToPaper from "vue-html-to-paper";
import firebase from "firebase/compat/app";
import "firebase/storage";
const options = {
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ],
  timeout: 50000,
};
Vue.use(VueHtmlToPaper, options);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7Gm_GKiIqwT_-KYgx0dnCOaTVGN9-1Kg",
  authDomain: "nwas-f974b.firebaseapp.com",
  projectId: "nwas-f974b",
  storageBucket: "nwas-f974b.appspot.com",
  messagingSenderId: "370031907423",
  appId: "1:370031907423:web:4a18f1339dbd0dfd4381ea",
  measurementId: "G-VS7DHS7TQT",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// for authenticating thru headers with tokens
// Get a reference to the storage service
// const storage = firebase.storage();

require("@/store/subscriber");
Vue.config.provductionTip = false;
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
axios.defaults.baseURL = "https://server.portal.nwas.online/api";

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    // storage,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
